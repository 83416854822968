import { Link } from "react-router-dom";

export default function PropertyTypes () {
    return <>
      <div className="container-xxl py-5">
          <div className="container">
              <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "600px"}}>
                  <h1 className="mb-3">Available Now</h1>
                   <p>Our Services includes the following</p>
              </div>
              <div className="row g-4">
                  <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                      <Link to="/" className="cat-item d-block bg-light text-center rounded p-3">
                          <div className="rounded p-4">
                              <div className="icon mb-3">
                                  <img className="img-fluid" src="/assets/img/icon-apartment.png" alt="Icon" />
                              </div>
                              <h6>Apartments</h6>
                              {/* <span>123 Properties</span> */}
                          </div>
                      </Link>
                  </div>
                  <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                      <Link to="/" className="cat-item d-block bg-light text-center rounded p-3">
                          <div className="rounded p-4">
                              <div className="icon mb-3">
                                  <img className="img-fluid" src="/assets/img/icon-villa.png" alt="Icon" />
                              </div>
                              <h6>Estate Lands</h6>
                              {/* <span>123 Properties</span> */}
                          </div>
                      </Link>
                  </div>
                  <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                      <Link to="/" className="cat-item d-block bg-light text-center rounded p-3">
                          <div className="rounded p-4">
                              <div className="icon mb-3">
                                  <img className="img-fluid" src="/assets/img/icon-house.png" alt="Icon" />
                              </div>
                              <h6>Home</h6>
                              {/* <span>123 Properties</span> */}
                          </div>
                      </Link>
                  </div>
                  <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                      <Link to="/" className="cat-item d-block bg-light text-center rounded p-3">
                          <div className="rounded p-4">
                              <div className="icon mb-3">
                                  <img className="img-fluid" src="/assets/img/icon-housing.png" alt="Icon" />
                              </div>
                              <h6>Duplexes</h6>
                              {/* <span>123 Properties</span> */}
                          </div>
                      </Link>
                  </div>
                  <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                      <Link to="/" className="cat-item d-block bg-light text-center rounded p-3">
                          <div className="rounded p-4">
                              <div className="icon mb-3">
                                  <img className="img-fluid" src="/assets/img/icon-building.png" alt="Icon" />
                              </div>
                              <h6>Bungalows</h6>
                              {/* <span>123 Properties</span> */}
                          </div>
                      </Link>
                  </div>
                  <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                      <Link to="/" className="cat-item d-block bg-light text-center rounded p-3">
                          <div className="rounded p-4">
                              <div className="icon mb-3">
                                  <img className="img-fluid" src="/assets/img/icon-neighborhood.png" alt="Icon" />
                              </div>
                              <h6>Offices</h6>
                              {/* <span>123 Properties</span> */}
                          </div>
                      </Link>
                  </div>
                  <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                      <Link to="/" className="cat-item d-block bg-light text-center rounded p-3">
                          <div className="rounded p-4">
                              <div className="icon mb-3">
                                  <img className="img-fluid" src="/assets/img/icon-condominium.png" alt="Icon" />
                              </div>
                              <h6>Shops</h6>
                              {/* <span>123 Properties</span> */}
                          </div>
                      </Link>
                  </div>
                  <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                      <Link to="/" className="cat-item d-block bg-light text-center rounded p-3">
                          <div className="rounded p-4">
                              <div className="icon mb-3">
                                  <img className="img-fluid" src="/assets/img/icon-luxury.png" alt="Icon" />
                              </div>
                              <h6>Resorts</h6>
                              {/* <span>123 Properties</span> */}
                          </div>
                      </Link>
                  </div>
              </div>
          </div>
      </div>
    </>
}