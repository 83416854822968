
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './bootstrap.min.css';
import './App.css';
import Home from './Home';
import AboutUs from "./components/about/AboutUs";
import ContactUs from "./components/contact/ContactUs";
import P404 from "./components/error/p404";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import ContactUsSuccess from "./components/contact/ContactUsSuccess";
import Services from "./components/services/Services";

export default function App() {
  return (
    <BrowserRouter>
    <Navbar />
    <Routes>
      <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/contact/success" element={<ContactUsSuccess />} />
        <Route path="/services" element={<Services />} />
        <Route path="*" element={<P404 />} />
    </Routes>
    <Footer />
  </BrowserRouter>

  );
}