import {useState} from 'react';

export default function ContactUs() {
    const [message] = useState('');

    const initialForm = {
        email: '',
        names: '',
        userMessage: '',
    }

    const [inputField, setInputField] = useState(initialForm)

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputField((prevState)=>({
          ...prevState,
          [name]: value
        }));
      }

    const { names, email, userMessage } = inputField;

    return (
        <div className="container row m-0 p-0">
            <div className="p-3 pt-5 col-sm-9 mx-auto text-center row" style={{background: '#fff'}}>                
                <h4 className="text-center">Feel free to reach-out to us and we'll get in touch with you</h4>
                <div className="col-sm-6">
                    <div className="text-left">
                        <form 
                            action="https://formsubmit.co/7a3fbfcdab5e7d7022a20143e9320038"
                            method="POST"
                        >
                            <div className="row">
                                <div className="my-2">
                                    <input 
                                        name="names" 
                                        className="form-control" 
                                        placeholder="Your names *" 
                                        value={names}
                                        onChange={handleInputChange}
                                        required 
                                    />                        
                                </div>

                                <div className="my-2">
                                    <input 
                                        name="email" 
                                        type="email"
                                        className="form-control" 
                                        placeholder="Email *" 
                                        value={email}
                                        onChange={handleInputChange} 
                                        required
                                    />
                                </div>

                                <div className="my-2">
                                    <textarea 
                                        onChange={ handleInputChange } 
                                        name="userMessage" 
                                        value={ userMessage } 
                                        className="form-control h4" 
                                        placeholder="Your message *" 
                                        rows={5}
                                    />
                                </div>

                                {
                                    message && 
                                    <div className="text-center p-2 text-danger">{message}</div>
                                }

                                <input type="hidden" name="_subject" value="New Contact Us Message!" />
                                {/* <input type="hidden" name="_cc" value="another@email.com,yetanother@email.com"> */}
                                {/* <input type="hidden" name="_blacklist" value="spammy pattern, banned term, phrase"> */}
                                <input type="hidden" name="_captcha" value="false"></input>
                                <input type="hidden" name="_autoresponse" value={`Thank you ${names} for reaching out, we'll get back to you as soon as we can.`} />
                                <input type="hidden" name="_template" value="table" />
                                <input type="hidden" name="_next" value={`${window.location.origin}/contact/success`}></input>
                                {/* <input type="hidden" name="_webhook" value="https://yourdomain.co/your-webhook" /> */}

                                <div className='text-center'>
                                    <button 
                                        type="submit" 
                                        className="btn btn-lg btn-primary" 
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div>
                        <h3>You can also send us a mail: </h3> 
                        <div><strong>{process.env.REACT_APP_CONTACT_US_EMAIL}</strong></div>                    
                    </div>                    
                </div>
            </div>            
        </div>
    );
}