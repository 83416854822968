import { Link } from "react-router-dom";

export default function Navbar () {
    const windowWidth = window.innerWidth;
    // const windowHeight = window.innerHeight;
    const appName = process.env.REACT_APP_COMPANY_NAME;

    return <>
        <div className="container-fluid nav-bar bg-transparent m-0 p-0">
            <nav className="navbar navbar-expand-lg bg-white navbar-light py-0 px-4">
                <Link to="/">                    
                    <h1 className="m-0 text-primary">
                        <span className="icon p-2 me-2">
                            <img className="img-fluid" src="/assets/img/logo.jpeg" alt="Icon" style={{width: '30px', height: '30px'}} />
                        </span>
                        <span className="fs-1 d-none d-sm-inline">{appName}</span>
                        <span className="fs-5 d-sm-none">{windowWidth >= 363 ? appName : appName.substring(0, appName.length - 4)}</span>
                    </h1>
                </Link>
                <button 
                    type="button" 
                    className="navbar-toggler" 
                    data-bs-toggle="collapse" 
                    data-bs-target="#navbarCollapse"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto">
                        <Link to="/" className="nav-item nav-link active">Home</Link>
                        <Link to="/about" className="nav-item nav-link">About</Link>
                         {/* <div className="nav-item dropdown">
                            <Link to="/" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Property</Link>
                            <div className="dropdown-menu rounded-0 m-0">
                                <Link to="/" className="dropdown-item">Property List</Link>
                                <Link to="/" className="dropdown-item">Property List</Link>
                                <Link to="/" className="dropdown-item">Property List</Link>
                            </div>
                        </div> */}
                        {/* <div className="nav-item dropdown">
                            <Link to="/" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</Link>
                            <div className="dropdown-menu rounded-0 m-0">
                                <a href="testimonial.html" className="dropdown-item">Testimonial</a>
                                <a href="404.html" className="dropdown-item">404 Error</a>
                            </div>
                        </div> */}
                        <Link to="/contact" className="nav-item nav-link">Contact</Link>
                    </div>
                    {/* <Link to="/" className="btn btn-primary px-3 d-none d-lg-flex">Add Property</Link> */}
                </div>
            </nav>
        </div>    
    </>
}