import TeamMembers from "../team/TeamMembers";

export default function AboutUs () {
    return <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                        <div className="about-img position-relative overflow-hidden p-5 pe-0">
                            <img className="img-fluid w-100" src="/favicon.ico" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                    <h3>
                        We are a real marketing, investment and development company with a vibrant team, working together to bring you great and profitable deals
                    </h3>
                    </div>
                </div>
                <TeamMembers />
            </div>
        </div>
}