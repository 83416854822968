import { Link } from "react-router-dom";

export default function TeamMembers () {
    return       <div className="container-xxl py-5">
    <div className="container">
        {/* <div className="row g-0 gx-5 align-items-end">
            <div className="col-lg-6">
                <div className="text-start mx-auto mb-5 wow slideInLeft" data-wow-delay="0.1s">
                    <h1 className="mb-3">Team Members</h1>
                    <p>Eirmod sed ipsum dolor sit rebum labore magna erat. Tempor ut dolore lorem kasd vero ipsum sit eirmod sit diam justo sed rebum.</p>
                </div>
            </div>
        </div> */}
        <div className="tab-content">
            <div id="tab-1" className="tab-pane fade show p-0 active">
                <div className="row g-4">
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="property-item rounded overflow-hidden">
                            <div className="position-relative overflow-hidden">
                                {/* <Link to="/"> */}
                                    <img className="img-fluid" src="/assets/img/adekusibe.jpg" alt="" />
                                {/* </Link> */}
                                <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">MD/CEO</div>
                                <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">
                                    Everyone deserves a decent shelter
                                </div>
                            </div>
                            <div className="p-4 pb-0">
                                <h5 className="text-primary mb-3">Adekusibe O. Adedeji</h5>
                                <Link to="/" className="d-block h5 mb-2" >MD / CEO</Link>
                                <p>                                    
                                    He is a graduate of Medical Laboratory Science (BMLS) from Ambrose Alli University, Ekpoma.  Holder of professional Certificates in real estate marketing, investments/development,  business management, business consulting, marketing, sales and leadership development.
                                </p>
                            </div>
                            {/* <div className="d-flex border-top">
                                <small className="flex-fill text-center border-end py-2"><i className="fa fa-ruler-combined text-primary me-2"></i>300 SQM</small>
                                <small className="flex-fill text-center border-end py-2"><i className="fa fa-bed text-primary me-2"></i>500 SQM</small>
                                <small className="flex-fill text-center py-2"><i className="fa fa-bath text-primary me-2"></i>2 Bath</small>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
}