import axios from 'axios';
import { useState } from 'react';

export default function NewsLetterSubscription(){
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [canShowForm, setCanShowForm] = useState(true);

    const subscribeToNewsLetter = async ()=> {
        try{

            const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

            if (!email.match(validEmailRegex)) {
                alert("It seemed you entered and invalid email address.");
                return 
            } 

            const res = await axios.post(`${process.env.REACT_APP_API_PATH}public/news-letter/subscribe`, {
                email
            });

            const { error, message } = res.data;
            setMessage(message);
            alert(message);

            if(!error){
                setCanShowForm(false);
            }
        }catch(err){
            console.error(err);
            const message = err.response.data.message || err.message;
            setMessage(message);
        }
    }

    return (
        <div>
            {
                message && <div className="text-center">{message}</div>
            }

            {
                canShowForm && 
                <div>
                    <h5 className="text-white mb-4">Newsletter</h5>
                    <p className="text-center">Subscribe to our news letter to occasionally get updates</p>
                    <div className="position-relative mx-auto" style={{maxWidth: "400px"}}>
                        <input 
                            className="form-control bg-transparent w-100 py-3 ps-4 pe-5" 
                            placeholder="Your email" 
                            name="email"
                            type="email"
                            value={email}
                            onChange={(e)=>{
                                    setEmail(e.target.value);
                                    setMessage("");
                                }
                            }
                        />
                        <button 
                            type="button" 
                            className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
                            onClick={()=> subscribeToNewsLetter()}
                        >
                            Subscribe
                        </button>
                    </div>
                </div>
            }
        </div>
    );
}