export default function ContactUsSuccess () {
    return <div className="p-3 pt-5 col-sm-9 mx-auto text-center row">
        <div className="col-sm-6 pt-3">
            Thanks for reaching out. We'll get in touch with you as soon as possible. Keep an eye on your email
        </div>
        <div className="col-sm-6">
            <h5>You can also reach us via email or phone: </h5> 
            <div>
                <strong>{process.env.REACT_APP_CONTACT_US_EMAIL}</strong> or &nbsp;
                <strong>{process.env.REACT_APP_CONTACT_US_PHONE}</strong>
            </div>                                    
        </div>
    </div>
}