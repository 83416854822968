import { Link } from "react-router-dom";

export default function CallUs () {
    return <div className="container-xxl py-5">
        <div className="container">
            <div className="bg-light rounded p-3">
                <div className="bg-white rounded p-4" style={{border: "1px dashed rgba(0, 185, 142, .3)"}}>
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                            <img className="img-fluid rounded w-100" src="/assets/img/contactus.png" alt="Contact Us" />
                        </div>
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                            <div className="mb-4">
                                <h1 className="mb-3">Contact With Our Certified Agent</h1>
                                <p>Kindly reach out to us today</p>
                            </div>                            
                            <Link 
                                title="Click to call now"
                                to={`tel:${process.env.REACT_APP_CONTACT_US_PHONE}`}
                                className="btn btn-primary py-3 px-4 me-2">
                                <i className="fa fa-phone-alt me-2" ></i>
                                Make A Call {process.env.REACT_APP_CONTACT_US_PHONE}
                            </Link>
                            <Link 
                                title="Click to send mail"
                                to={`mailto:${process.env.REACT_APP_CONTACT_US_EMAIL}?subject=&body=`} 
                                className="btn btn-dark py-3 px-4"
                            >
                                <i className="fa fa-envelope me-2"></i>
                                You can Send us a mail today
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}