export default function SocialHandles(){
    return  <>
            <div className=" text-center">
                <h5 className="mb-4">Follow us on social media</h5>
                <div className="d-flex pt-2 text-center row m-0">
                    <div className="col">
                        <a 
                            href="#/" 
                            className="btn btn-social" 
                        >
                            <i className="fab fa-twitter" title="twitter" style={{fontSize: 50}}></i>
                            <div><b>Twitter</b></div>
                        </a>
                    </div>
                    <div className="col">
                        <a 
                            className="btn btn-social" 
                            href="https://web.facebook.com/profile.php?id=100063708396611" 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            <i className="fab fa-facebook-f" title="Facebook" style={{fontSize: 50}}></i>
                            <div><b>Facebook</b></div>
                        </a>
                    </div>
                    <div className="col">
                        <a 
                            href="#/" 
                            className="btn btn-social">
                                <i className="fab fa-youtube" title="Youtube" style={{fontSize: 50}}></i>
                                <div><b>Youtube</b></div>
                        </a>
                    </div>
                    <div className="col">
                        <a 
                            href="#/" 
                            className="btn btn-social" >
                                <i className="fab fa-linkedin-in" title="Linkedin" style={{fontSize: 50}}></i>
                                <div><b>Linkedin</b></div>
                        </a>
                    </div>
                    <div className="col">
                        <a 
                            href="#/" 
                            className="btn btn-social" >
                                <i className='fab fa-instagram' title="Instagram" style={{fontSize: 50}}></i>
                                <div><b>Instagram</b></div>
                        </a>
                    </div>
                    <div className="col">
                        <a 
                            href="#/" 
                            className="btn btn-social" >
                                <i className='fab fa-instagram' title="Tiktok" style={{fontSize: 50}}></i>
                                <div><b>Tiktok</b></div>
                        </a>
                    </div>
            </div>
        </div>
    </>
}