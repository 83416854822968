import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';

export default function DoYouKnow() {
  const [modalShow, setModalShow] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  useEffect(()=>{
    setModalShow(true);    
    loadQuestions();
  },[]);

  const loadQuestions = async () => {
    try{
      const res = await axios.get(`${process.env.REACT_APP_API_PATH}public/prompt-questions?page=1&size=10`)      

      if(res.status === +process.env.REACT_APP_RECORD_NOT_FOUND){

      }else{
        const { error, data } = res.data;
        
        if(!error && Array.isArray(data)){
          setQuestions(data);
        }
      }
    }catch(err){
      console.error(err);
    }
  }

  const submitAnswers = async () => {
    try{
      const res = await axios.post(`${process.env.REACT_APP_API_PATH}public/prompt-answers/create`,{
        email,
        data: answers
      })      

      const { error, message } = res.data;

      alert(message);
      
      if(!error){
        setEmail('');
        setModalShow(false);
      }
    }catch(err){
      const { data } = err.response;      
      let message = data.message || err.message;

      if(Array.isArray(data) && data.length > 0){
        message = data[0].msg
      }

      setMessage(message);

      alert(message);
    }
  }


  if(questions.length < 1){
    return '';
  }else{
    return (
      <>
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Do you Know we can help you achieve your real estate goals?
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className='container'>
              <div className='text-center h6'>Please answer the following questions and let's get started</div>
              {
                questions.map((q, i)=>{
                  return <div key={q.id} className='container my-2'>
                      <strong>Question{i+1}: &nbsp;</strong>
                      {q.question}                 
                        <div>
                          <input 
                            type="radio" 
                            id={q.id+'yes'} 
                            name={q.id} 
                            value="Yes" 
                            onChange={(e)=>{
                              setMessage('');
                              const oldAnswer = answers.filter((answer) => answer.questionId === q.id);
                              if(oldAnswer.length > 0){
                                const index = answers.findIndex(item => item.questionId === q.id);
                                answers.splice(index, 1, {questionId: q.id, answer: e.target.value});
                              }else{
                                setAnswers([...answers, {questionId: q.id, answer: e.target.value}])
                              }
                            }} 
                          />
                          <label htmlFor={q.id+'yes'}>YES</label> &nbsp;&nbsp;
                          <input 
                            type="radio" 
                            id={q.id+'no'} 
                            name={q.id} 
                            value="No" 
                            onChange={(e)=>{
                              setMessage('');
                              const oldAnswer = answers.filter((answer) => answer.questionId === q.id);
                              if(oldAnswer.length > 0){
                                const index = answers.findIndex(item => item.questionId === q.id);
                                answers.splice(index, 1, {questionId: q.id, answer: e.target.value});
                              }else{
                                setAnswers([...answers, {questionId: q.id, answer: e.target.value}])
                              }
                            }} 

                          />
                          <label htmlFor={q.id+'no'}>NO</label>
                        </div>
                  </div>
                })
              }

              <div>
                <input 
                    className="form-control bg-transparent w-100 py-3 ps-4 pe-5" 
                    placeholder="Your email" 
                    name="email"
                    type="email"
                    value={email}
                    required
                    onChange={(e)=>{
                          setEmail(e.target.value);
                          setMessage("");
                        }
                    }
                />
              </div>

              {
                message && <div className="text-center text-danger bold my-3">{message}</div>
              }
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={()=> submitAnswers()}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}