import About from "./components/about/About";
import CallUs from "./components/contact/CallUs";
import DoYouKnow from "./components/doYouKnow/DoYouKnow";
import Faq from "./components/faq/Faq";
import SocialHandles from "./components/footer/SocialHandles";
import Header from "./components/header/Header";
import PropertyListing from "./components/properties/PropertyListing";
import PropertyTypes from "./components/properties/PropertyTypes";
import Spinner from "./components/spinner/Spinner";

function Home() {
    return <>    
        <div className="container-xxl bg-white p-0">            
            <Spinner />
            <Header />
            {/* <Search /> */}
            <PropertyTypes />
            <About />
            <PropertyListing />
            <CallUs />
            {/* <Agents /> */}
            {/* <Testimonies /> */}
            <Faq />
            <SocialHandles />
            <DoYouKnow />            
        </div>
    </>
}

export default Home;