// import { Link } from 'react-router-dom';


// import './assets/owl.carousel.min.css';
// import '../assets/css/style.css';
// import '../assets/css/responsive.css';
import React from 'react';
import VideoPlayer from '../video/VideoPlayer';
// import Faq from '../faq/Faq';
// import { Helmet } from 'react-helmet';

// const useScript = (src) => {
// useEffect(() => {
// const tag = document.createElement('script');
// tag.async = true;
// tag.src = src;
// document.body.appendChild(tag);

// return () => {
//   document.body.removeChild(tag);
// }
// }, [src])
// } 

export default function Header () {
    // useScript('./assets/js/jquery.js');
    // useScript('./assets/js/bootstrap.min.js');
    // useScript('./assets/js/popper.min.js');      
    // useScript('./assets/js/jquery-ui.js');
    // useScript('./lib/owlcarousel/owl.carousel.min.js');

    // useEffect(()=>{
    //     const script = document.createElement("script");

    //     script.src = "https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js";
    //     script.async = true;
    
    //     document.body.appendChild(script);
    // },[]);


    return <>
        <div className="container-fluid header p-0 mt-1">
            <div className="row g-0 align-items-center flex-column-reverse flex-md-row">
                <div className="col-md-6 p-5 mt-lg-5">
                    <h1 className="display-5 animated fadeIn mb-4">Find the best Real Estate Investment and a  <span className="text-primary">Perfect Home</span> for Your Family</h1>
                     <p className="animated fadeIn mb-4 pb-2">With {process.env.REACT_APP_COMPANY_NAME} all your real estate needs are covered. Contact us today</p>
                    {/* <Link to="/" className="btn btn-primary py-3 px-5 me-3 animated fadeIn">Get Started</Link> */}
                </div>
                <div className="col-md-6 animated fadeIn">
                    <VideoPlayer />
                    {/* <div className="owl-carousel header-carousel">
                        <div className="owl-carousel-item"> 
                            <img className="img-fluid" src="/assets/img/carousel-1.jpg" alt="" />
                        </div>
                        <div className="owl-carousel-item">
                            <img className="img-fluid" src="/assets/img/carousel-2.jpg" alt="" />
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </>
}