import { Link } from "react-router-dom";

export default function PropertyListing () {
    return       <div className="container-xxl py-5">
    <div className="container">
        <div className="row g-0 gx-5 align-items-end">
            <div className="col-lg-6">
                <div className="text-start mx-auto mb-5 wow slideInLeft" data-wow-delay="0.1s">
                    <h1 className="mb-3">Property Listing</h1>
                    {/* <p>Eirmod sed ipsum dolor sit rebum labore magna erat. Tempor ut dolore lorem kasd vero ipsum sit eirmod sit diam justo sed rebum.</p> */}
                </div>
            </div>
            {/* <div className="col-lg-6 text-start text-lg-end wow slideInRight" data-wow-delay="0.1s">
                <ul className="nav nav-pills d-inline-flex justify-content-end mb-5">
                    <li className="nav-item me-2">
                        <Link to="/" className="btn btn-outline-primary active" data-bs-toggle="pill" href="#tab-1">Featured</Link>
                    </li>
                    <li className="nav-item me-2">
                        <Link to="/" className="btn btn-outline-primary" data-bs-toggle="pill" href="#tab-2">For Sale</Link>
                    </li>
                    <li className="nav-item me-0">
                    <Link to="/" className="btn btn-outline-primary" data-bs-toggle="pill" href="#tab-3">For Sale</Link>
                    </li>
                </ul>
            </div> */} 
        </div>
        <div className="tab-content">                                                     
            <div className="tab-pane fade show p-0 active">
                <div className="row g-4">
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="property-item rounded overflow-hidden">
                            <div className="position-relative overflow-hidden">
                                <Link to="/"><img className="img-fluid" src="/assets/img/dominion.jpeg" alt="" /></Link>
                                <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">For Sale</div>
                                <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">Land</div>
                            </div>
                            <div className="p-4 pb-0">
                                <h5 className="text-primary mb-3">N28M - 300SQM, 48M - 500SQM</h5>
                                <Link to="/" className="d-block h5 mb-2">Dominion Court</Link>
                                <p><i className="fa fa-map-marker-alt text-primary me-2"></i>Off Monastery Road, Sangotedo - Ajah, Lagos.</p>
                                <p>A Wise Investor's Choice! Dominion Court, is the wise choice for investors seeking buy and build land in the monastery axis of Sangotedo. Title: C of O/Registered Deed.</p>
                            </div>
                            <div className="d-flex border-top">
                                <small className="flex-fill text-center border-end py-2"><i className="fa fa-ruler-combined text-primary me-2"></i>300 SQM</small>                                
                                <small className="flex-fill text-center border-end py-2"><i className="fa fa-ruler-combined text-primary me-2"></i>500 SQM</small>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="property-item rounded overflow-hidden">
                            <div className="position-relative overflow-hidden">
                                <Link to="/">
                                    <img className="img-fluid" src="/assets/img/glamour-estate.jpeg" alt="" />
                                </Link>
                                <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">For Sale</div>
                                <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">Land</div>
                            </div>
                            <div className="p-4 pb-0">
                                <h5 className="text-primary mb-3">N3.5M</h5>
                                <Link to="/" className="d-block h5 mb-2">Glamour Estate</Link>
                                {/* <p><i className="fa fa-map-marker-alt text-primary me-2"></i></p> */}
                            </div>
                            <div className="d-flex border-top">
                                <small className="flex-fill text-center border-end py-2"><i className="fa fa-ruler-combined text-primary me-2"></i>500 SQM</small>                                
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="property-item rounded overflow-hidden">
                            <div className="position-relative overflow-hidden">
                                <Link to="/"><img className="img-fluid" src="/assets/img/dominion_court2.png" alt="" /></Link>
                                <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">For Sale</div>
                                <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">Land</div>
                            </div>
                            <div className="p-4 pb-0">
                                <h5 className="text-primary mb-3">N20M</h5>
                                <Link to="/" className="d-block h5 mb-2">Dominion Court</Link>
                                <p><i className="fa fa-map-marker-alt text-primary me-2"></i>
                                Off Monastery Road, behind the Shoprite, Sangotedo, Lagos. </p>
                            </div>
                            <div className="d-flex border-top">
                                <small className="flex-fill text-center border-end py-2"><i className="fa fa-ruler-combined text-primary me-2"></i>300 SQM</small>
                                <small className="flex-fill text-center border-end py-2"><i className="fa fa-bed text-primary me-2"></i>500 SQM</small>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="property-item rounded overflow-hidden">
                            <div className="position-relative overflow-hidden">
                                <Link to="/"><img className="img-fluid" src="/assets/img/emerald.jpeg" alt="" /></Link>
                                <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">For Sale</div>
                                <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">Building</div>
                            </div>
                            <div className="p-4 pb-0">
                                <h5 className="text-primary mb-3">N1.7M</h5>
                                <Link to="/" className="d-block h5 mb-2" >Emerald</Link>
                                <p><i className="fa fa-map-marker-alt text-primary me-2"></i>Situated 5 minutes to the Film City, facing the road that connects to the New International Airport, Lekki. This beautiful estate land is a freehold with C of O in view.</p>
                            </div>
                            <div className="d-flex border-top">
                                <small className="flex-fill text-center border-end py-2"><i className="fa fa-ruler-combined text-primary me-2"></i>300 SQM</small>
                                <small className="flex-fill text-center border-end py-2"><i className="fa fa-bed text-primary me-2"></i>500 SQM</small>
                                <small className="flex-fill text-center py-2"><i className="fa fa-bath text-primary me-2"></i>2 Bath</small>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="property-item rounded overflow-hidden">
                            <div className="position-relative overflow-hidden">
                                <Link to="/"><img className="img-fluid" src="/assets/img/diamond.jpeg" alt="" /></Link>
                                <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">For Sale</div>
                                <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">Home</div>
                            </div>
                            <div className="p-4 pb-0">
                                <h5 className="text-primary mb-3">N1.5M</h5>
                                <Link to="/" className="d-block h5 mb-2" >Diamond City</Link>
                                <p><i className="fa fa-map-marker-alt text-primary me-2"></i>Neighbourhood: Lagos Film City, Ketu Omu Hospital, Epe Resort and SPA, Lagos Food Hub, Yabatech, Lekki Epe Airport, Epe Toll Gate and lots more</p>
                            </div>
                            <div className="d-flex border-top">
                                <small className="flex-fill text-center border-end py-2"><i className="fa fa-ruler-combined text-primary me-2"></i>300 SQM</small>
                                <small className="flex-fill text-center border-end py-2"><i className="fa fa-bed text-primary me-2"></i>500 SQM</small>
                                <small className="flex-fill text-center py-2"><i className="fa fa-bath text-primary me-2"></i>2 Bath</small>
                            </div>
                        </div>
                    </div> */}

                    <div className="col-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                        <Link to="/" className="btn btn-primary py-3 px-5" >Browse More Property</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
}