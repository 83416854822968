export default function Spinner () {
    return <>
        {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div className="spinner-border text-primary" role="status" style={{width: '3rem', height: '3rem'}} >
                <span className="sr-only">Loading...</span>
            </div>
        </div> */}
    </>
} 

// import React from 'react';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
// import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';

// function DemoCarousel () {
//         return (
//             <Carousel>
//                 <div>
//                     <img src="/favicon.ico" />
//                     <p className="legend">Legend 1</p>
//                 </div>
//                 <div>
//                     <img src="/assets/img/about.jpg" />
//                     <p className="legend">Legend 2</p>
//                 </div>
//             </Carousel>
//         );
// }

// export default DemoCarousel;