import { useState, useEffect } from 'react';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';

export default function Faq() {  
  const [faqs, setFaqs] = useState([]);

  useEffect(()=>{
    loadFaqs();
  },[]);

  const loadFaqs = async () => {
    try{
      const res = await axios.get(`${process.env.REACT_APP_API_PATH}public/faq`);
      const { error, data } = res.data;

      if(!error && Array.isArray(data) && data.length > 0){
        setFaqs(data);
      }

    }catch(err){
      console.error(err);
    }
  }

  return (
      <div className="container-fluid p-sm-3 mt-5" style={{background: "#fff"}}>
            <Accordion> 
              {
                faqs.map((item)=>{
                  return <Accordion.Item eventKey={item.id} key={item.id}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                }) 
              }
        </Accordion>
  </div>
  );
}