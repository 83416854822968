import { Link } from "react-router-dom";
import NewsLetterSubscription from "./NewsLetterSubscription";

export default function Footer () {
    return  <div className="container-fluid bg-dark text-white-50 footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
    <div className="container py-5">
        <div className="row g-5">
            <div className="col-lg-3 col-md-6">
                <h5 className="text-white mb-4">Get In Touch</h5>
                <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>2nd floor, Silverbird Galleria, Plot 113, Ahmadu Bello Way, Victoria Island., Lagos, Nigeria</p>
                <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>{process.env.REACT_APP_CONTACT_US_PHONE}</p>
                <p className="mb-2"><i className="fa fa-envelope me-3"></i>{process.env.REACT_APP_CONTACT_US_EMAIL}</p>
                <div className="d-flex pt-2">
                    <a href="#/" className="btn btn-outline-light btn-social" ><i className="fab fa-twitter"></i></a>
                    <a className="btn btn-outline-light btn-social" href="https://web.facebook.com/profile.php?id=100063708396611" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                    <a href="#/" className="btn btn-outline-light btn-social" ><i className="fab fa-youtube"></i></a>
                    <a href="#/" className="btn btn-outline-light btn-social" ><i className="fab fa-linkedin-in"></i></a>
                </div>
            </div>
            <div className="col-lg-3 col-md-6">
                <h5 className="text-white mb-4">Quick Links</h5>
                <Link to="/about" className="btn btn-link text-white-50">About Us</Link>
                <Link to="/contact" className="btn btn-link text-white-50">Contact Us</Link>
                <Link to="/services" className="btn btn-link text-white-50">Our Services</Link>
                <Link to="/" className="btn btn-link text-white-50">Privacy Policy</Link>
                <Link to="/" className="btn btn-link text-white-50">Terms & Condition</Link>
            </div>
            <div className="col-lg-3 col-md-6">
                <h5 className="text-white mb-4">Photo Gallery</h5>
                <div className="row g-2 pt-2">
                    <div className="col-4">
                        <img className="img-fluid rounded bg-light p-1" src="/assets/img/diamond.jpeg" alt="" />
                    </div>
                    <div className="col-4">
                        <img className="img-fluid rounded bg-light p-1" src="/assets/img/emerald.png" alt="" />
                    </div>
                    <div className="col-4">
                        <img className="img-fluid rounded bg-light p-1" src="/assets/img/emerald.jpeg" alt="" />
                    </div>
                    {/* <div className="col-4">
                        <img className="img-fluid rounded bg-light p-1" src="/assets/img/dominion_court.png" alt="" />
                    </div>
                    <div className="col-4">
                        <img className="img-fluid rounded bg-light p-1" src="/assets/img/dominion_court2.png" alt="" />
                    </div>
                    <div className="col-4">
                        <img className="img-fluid rounded bg-light p-1" src="/assets/img/diamond.jpeg" alt="" />
                    </div> */}
                </div>
            </div>
            <div className="col-lg-3 col-md-6">
                <NewsLetterSubscription />
            </div>
        </div>
    </div>
    <div className="container">
        <div className="copyright">
            <div className="row">
                <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                    &copy; <Link to="/" className="border-bottom" href="https://suitableshelters.com.ng">{process.env.REACT_APP_COMPANY_NAME}</Link>, All Right Reserved. 
      Designed By <Link to="/" className="border-bottom" href="https://greatftech.com" target="_blank" rel="noreferrer noopener">Great FTech</Link>
                </div>
                <div className="col-md-6 text-center text-md-end">
                    <div className="footer-menu">
                        <Link to="/">Home</Link>
                        <Link to="/">Cookies</Link>
                        <Link to="/">Help</Link>
                        <Link to="/">FQAs</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
}